.tabs {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  width: 100%;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);

  .tabs-names {
    border-bottom: solid 1px lightgray;
    display: flex;
    justify-content: space-around;

    .tabs-name {
      cursor: pointer;
      height: 100%;
      letter-spacing: 1px;
      padding: 20px 0px;
      text-align: center;
      width: 100%;

      &:not(:last-child) {
        border-right: solid 1px lightgray;
      }

      &:hover {
        color: gray;
      }

      &.selected {
        text-decoration: underline;
      }
    }
  }

  .tabs-content {
    padding: 20px;

    .tab {
      &.hidden {
        display: none;
      }
    }
  }
}
