footer {
  align-items: center;
  background: #222;
  color: white;
  display: flex !important;
  flex-direction: column;
  height: 500px;
  justify-content: center;
  width: 100%;

  .footer-section {
    display: flex;
    flex-direction: row;

    &.footer-contact {
      gap: 2rem;
      margin-bottom: 50px;

      .footer-contact-item {
        letter-spacing: 1px;

        a {
          color: white;
        }
      }
    }

    &.footer-socialNetworks {
      gap: 5rem;

      .footer-socialNetworks-item a {
        color: white;
        font-size: 2em;
      }
    }
  }

  @media (max-width: 768px) {
    height: 100vh;

    .footer-section {
      flex-direction: column;

      &.footer-contact {
        align-items: center;
        gap: 1rem;
      }

      &.footer-socialNetworks {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1rem;
        justify-content: center;

        .footer-socialNetworks-item {
          flex: 0 0 40%;
          text-align: center;
        }
      }
    }
  }
}
