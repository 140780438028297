.Modal {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  pointer-events: none;

  &>* {
    pointer-events: auto;
  }
}

.Overlay {
  backdrop-filter: blur(8px);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
