:root {
  --color-primary: #41516C;
  --color-secondary: #1B5F8C;
  --color-tertiary: #4CADAD;

  --color-background: #FCFCFC;
  --color-background-alternative: #F5F5F5;

  --color-text: #4F4F4F;
}
